<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items color="primary">
          <v-btn depressed color="primary" @click="closeDialog"> close </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title>
        <span class="headline">Items</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="editedItem?.document_lines"
          :search="search"
          dense
        >
          <!-- To Release -->
          <template v-slot:item.OpenQty="props">
            <v-text-field
              :disabled="props.item.LineStatus === 'C'"
              outlined
              dense
              v-model="props.item.OpenQty"
              single-line
            ></v-text-field>
          </template>
          <!-- end -->
          <!--Line status-->
          <template v-slot:item.LineStatus="props">
            <span
              :style="{
                color: props.item.LineStatus === 'O' ? 'green' : 'red',
              }"
            >
              {{ props.item.LineStatus === "O" ? "Open" : "Closed" }}
            </span>
          </template>
          <!--End Line status-->
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loader: false,
      selectedItems: [],
      search: null,
      selectedData: null,
      groupByEnabled: false,
      headers: [
        { text: "#", value: "id" },
        { text: "Line No.", value: "LineNum" },
        { text: "Dscription", value: "Dscription" },
        { text: "Item Code", value: "ItemCode" },
        { text: "Price", value: "Price" },
        { text: "LineStatus", value: "LineStatus" },
        { text: "Quantity", value: "Quantity" },
        { text: "OpenQty", value: "OpenQty" },
      ],
      orders: [],
    };
  },
  methods: {
    showDialog(item) {
      console.log("Item:", item);
      this.selectedData = item;
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>